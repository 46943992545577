import React, { useState, useEffect } from 'react'
import logo from '../assets/dental-logo.png'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  // Toggle menu for mobile devices
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Close menu on scroll up
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
        if (isMenuOpen) setIsMenuOpen(false) // Close the menu on scroll
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isMenuOpen])

  // Handle link click to prevent page refresh and smoothly scroll to section
  const handleLinkClick = (e, id, closeMenu = true) => {
    e.preventDefault() // Prevent the default anchor link behavior
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }

    // Close the menu after clicking a link
    if (closeMenu) {
      setIsMenuOpen(false)
    }
  }

  return (
    <header
      className={`bg-blue-600 text-white py-4 ${isScrolled ? 'shadow-lg' : ''}`}
    >
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="logo" className="w-12 h-12 object-cover" />
          <h1 className="text-3xl font-bold">Dental Care</h1>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          <a
            href="#home"
            onClick={(e) => handleLinkClick(e, 'home')}
            className="hover:underline"
          >
            Home
          </a>
          <a
            href="#about"
            onClick={(e) => handleLinkClick(e, 'about')}
            className="hover:underline"
          >
            About
          </a>
          <a
            href="#services"
            onClick={(e) => handleLinkClick(e, 'services')}
            className="hover:underline"
          >
            Services
          </a>
          <a
            href="#contact"
            onClick={(e) => handleLinkClick(e, 'contact')}
            className="hover:underline"
          >
            Contact
          </a>
        </nav>

        {/* Mobile Menu Icon */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation (Modal/Card) */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bg-black bg-opacity-50 z-50 w-48 h-auto mt-16 mr-4 rounded-lg shadow-md">
          <div className="flex justify-end pt-4 pr-6">
            <button onClick={toggleMenu} className="text-white text-3xl">
              &times;
            </button>
          </div>
          <div className="space-y-4 px-4 py-2">
            <a
              href="#home"
              onClick={(e) => handleLinkClick(e, 'home')} // Do not scroll, just close menu
              className="block text-center text-lg text-white py-2"
            >
              Home
            </a>
            <a
              href="#about"
              onClick={(e) => handleLinkClick(e, 'about')}
              className="block text-center text-lg text-white py-2"
            >
              About
            </a>
            <a
              href="#services"
              onClick={(e) => handleLinkClick(e, 'services')}
              className="block text-center text-lg text-white py-2"
            >
              Services
            </a>
            <a
              href="#contact"
              onClick={(e) => handleLinkClick(e, 'contact')} // Do not scroll, just close menu
              className="block text-center text-lg text-white py-2"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
