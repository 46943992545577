import React, { useState, useEffect } from 'react'
import dental1 from '../assets/dental-1.jpg'
import dental2 from '../assets/dental-2.jpg'

const Carousel = () => {
  const images = [
    { src: dental1, caption: 'Welcome to Dental Care' },
    { src: dental2, caption: 'Your Smile, Our Priority' },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)

  // Automatically change slides every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [images.length])

  return (
    <div className="relative w-full overflow-hidden min-h-[600px]">
      {/* Carousel Slides */}
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            backgroundImage: `url(${image.src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="bg-black bg-opacity-50 h-full flex items-center justify-center text-white">
            <h2 className="text-3xl font-bold">{image.caption}</h2>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Carousel
