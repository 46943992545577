import React from 'react'
import doc1 from '../assets/doc1.png'
import doc2 from '../assets/doc2.png'
import doc3 from '../assets/doc3.png'

const AboutSection = () => {
  const experts = [
    {
      name: 'Dr. Sarah Johnson',
      specialization: 'Orthodontist',
      description: 'Expert in braces, aligners, and smile correction.',
      image: doc1,
    },
    {
      name: 'Dr. Michael Smith',
      specialization: 'Cosmetic Dentist',
      description:
        'Specializes in teeth whitening, veneers, and aesthetic dentistry.',
      image: doc2,
    },
    {
      name: 'Dr. Emily Davis',
      specialization: 'Oral Surgeon',
      description:
        'Experienced in wisdom tooth extractions and dental implants.',
      image: doc3,
    },
  ]

  return (
    <section id="about" className="py-12 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">About Us</h2>
        <p className="text-gray-600 mb-8">
          At Dental Care, we are committed to providing the best oral health
          solutions. Our expert team ensures pain-free procedures and
          comprehensive treatments to give you the smile you deserve.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {experts.map((expert, index) => (
            <div
              key={index}
              className="bg-gray-100 shadow-lg rounded-lg overflow-hidden"
            >
              <img
                src={expert.image}
                alt={expert.name}
                className="w-full h-96 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-bold text-blue-600">
                  {expert.name}
                </h3>
                <p className="text-sm text-gray-500">{expert.specialization}</p>
                <p className="text-gray-600 mt-2">{expert.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AboutSection
