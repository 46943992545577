import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import appointment from '../assets/appointment.png'

const ServicesSection = () => {
  const services = [
    {
      title: 'General Dentistry',
      description:
        'Regular checkups, cleanings, and preventive care for your healthy smile.',
      icon: '🦷',
    },
    {
      title: 'Cosmetic Dentistry',
      description:
        'Enhance your smile with teeth whitening, veneers, and more.',
      icon: '✨',
    },
    {
      title: 'Orthodontics',
      description:
        'Align your teeth and improve your bite with braces and aligners.',
      icon: '🪥',
    },
    {
      title: 'Oral Surgery',
      description:
        'Expert care for implants, wisdom teeth removal, and other surgeries.',
      icon: '🔪',
    },
    {
      title: 'Pediatric Dentistry',
      description: 'Gentle and specialized care for children’s dental needs.',
      icon: '👶',
    },
    {
      title: 'Endodontics',
      description: 'Root canal treatments to save your natural teeth.',
      icon: '🪛',
    },
    {
      title: 'Periodontics',
      description: 'Treat gum diseases and maintain healthy gums for life.',
      icon: '🌿',
    },
    {
      title: 'Emergency Dentistry',
      description: 'Quick relief for unexpected dental problems and injuries.',
      icon: '🚑',
    },
  ]

  return (
    <section id="services" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">Our Services</h2>
        <p className="text-gray-600 mb-8">
          We offer a wide range of dental care services to meet the unique needs
          of every patient. From routine checkups to advanced procedures, our
          experienced team is here for you.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300"
            >
              <div className="text-4xl mb-4 text-blue-600">{service.icon}</div>
              <h3 className="text-lg font-bold text-blue-600 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
        {/* Booking Section */}
        <div className="mt-12 bg-white p-6 rounded-lg shadow-lg flex flex-col md:flex-row items-center justify-between">
          <div className="flex items-center space-x-4">
            <img
              src={appointment}
              alt="Book Appointment"
              className="w-20 h-20 rounded-full"
            />
            <div className="text-left">
              <h3 className="text-lg font-bold text-blue-600">
                Book an Appointment
              </h3>
              <p className="text-gray-600">
                Call us now to schedule your visit.
              </p>
            </div>
          </div>
          <div className="mt-4 md:mt-0 text-center">
            <p className="text-lg font-semibold text-gray-800">
              📞 +91 96770 92050
            </p>
            <p className="text-sm text-gray-500">
              We are available 9 AM - 9 PM
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <a
              href="https://wa.me/919677092050"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-lg shadow-lg hover:bg-green-600 transition duration-300"
            >
              <FaWhatsapp className="mr-2 text-xl" /> WhatsApp Us
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
