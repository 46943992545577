import React from 'react'
import Header from './components/Header'
import Carousel from './components/Carousel'
import AboutSection from './components/AboutSection'
import ServicesSection from './components/ServicesSection'
import Footer from './components/Footer'

function App() {
  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <Carousel />
      <AboutSection />
      <ServicesSection />
      <Footer />
    </div>
  )
}

export default App
